var options = {
    controls: false,
    title: false,
    byline: false,
    responsive: true,
    pip: true,
};

const swiper = new Swiper('.testimonials-slider', {
    // Optional parameters
    effect: 'fade',
    speed: 500,
    fadeEffect: {
        crossFade: true
    },
    // direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    allowTouchMove: false,
    disableOnInteraction: false,
    noSwiping: false,
    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
    },
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

const videoPlayers = [];
const allControls = [];
const allPlayButtons = [];
const videoBlocks = [];

swiper.on('slideChange', function () {
    const index = swiper.previousIndex;

    videoPlayers.forEach((player) => {
        player.pause()
    });

    allControls.forEach(control => {
        control.style.display = 'none';
    });

    allPlayButtons.forEach(playbtn => {
        playbtn.style.display = 'block';
    });

    videoBlocks.forEach(block => {
        block.classList.remove('video_playing')
    })
});


const videoWrappers = document.querySelectorAll('.testimonial-video-wrapper');



videoWrappers.forEach((wrapper)=>{
  const videoBlock = wrapper.querySelector('.testimonial-video');
  const videoPlayer =  new Vimeo.Player(videoBlock, options);
  const videoProgressBarWrapper = wrapper.querySelector('.videoProgressBarWrapper')

  videoBlocks.push(videoBlock);
  videoPlayers.push(videoPlayer);


  const playButton = wrapper.querySelector('.testimonial-video-play');
  allPlayButtons.push(playButton)
  const pauseButton = wrapper.querySelector('.video-pause-button');
  const controls = wrapper.querySelector('.testimonials-video-controls');
  allControls.push(controls)
  const progressBar = wrapper.querySelector('.progressBar');

  let activeProgress = wrapper.querySelector('.activeProgress');

  playButton.addEventListener('click', ()=>{

    videoPlayer.play();
    hideBtn(playButton);
    showControls(controls);
    videoPlayer.on('timeupdate', onPlay);
    videoBlock.classList.add('video_playing')
  })

  pauseButton.addEventListener('click', ()=>{
    videoPlayer.pause();
    hideBtn(controls)
    showBtn(playButton)
    videoBlock.classList.remove('video_playing')
  })


  // PROGRESS BAR
  videoPlayer.getDuration().then(function(duration){
    progressBar.max = duration;
  })

  progressBar.addEventListener('input', ()=>{
    videoPlayer.setCurrentTime(progressBar.value)

    const x = progressBar.value  * 100 / progressBar.max
    activeProgress.style.width = x + '%'
  })

  var onPlay = function(data) {
    // console.log(data.seconds);
    progressBar.value = data.seconds;
    const x = data.seconds  * 100 / progressBar.max
    activeProgress.style.width = x + '%'
  };

  let intFrameWidth = window.innerWidth;
  window.addEventListener('resize', ()=>{
    intFrameWidth = window.innerWidth;
  })

    function onClassChange(element, callback) {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    callback(mutation.target);
                }
            });
        });
        observer.observe(element, {attributes: true});
        return observer.disconnect;
    }

    const videos = document.querySelectorAll('.testimonial-video');
    videos.forEach(el => {
        onClassChange(el, (node) => {
            if (node.classList.contains('video_playing')) {
                toggleVideoCtrls(node);
            }
        })
    });

    function toggleVideoCtrls(video) {
        const ctrl = video.querySelector('.testimonials-video-controls');
        const intFrameWidth = window.innerWidth;
        if (intFrameWidth <= 1024) {
            window.onclick = (e) => {
            if (e.target.className === 'testimonials-video-controls') {
                ctrl.classList.add('animated')

                    setTimeout(function () {
                        ctrl.classList.remove('animated')
                    }, 2000);
                }
            }
        } else {
            video.addEventListener('mouseover', (e) => {
                ctrl.classList.add('animated', 'visible')
            })
            video.addEventListener('mouseleave', (e) => {
                    if (e.target.classList.contains('video_playing')) {
                        ctrl.classList.remove('visible')
                        setTimeout(function () {
                            if (!ctrl.classList.contains('visible')) {
                                ctrl.classList.remove('animated')
                            }
                        }, 2000);
                    }
                }
            )
        }
    }


    // PROGRESS BAR END

})



const onLoad = function() {
  const event = document.createEvent('Event');
  event.initEvent('videoLoaded', true, true);
  window.dispatchEvent(event);
};


if (videoPlayers.length > 0){
  videoPlayers[0].on('loaded', onLoad);
}




function hideBtn(button) {
    button.style.display = 'none';
}

function showBtn(button) {
    button.style.display = 'block';
}

function showControls(button) {
    button.style.display = 'flex';
}



