const treatmentSeeMoreBtn = document.getElementById('treatmentSeeMoreBtn');

if (treatmentSeeMoreBtn) {

  const tabButtons = document.querySelectorAll('.treatment-cases-ages .tab-button');
  const tabs = document.querySelectorAll('.treatment-cases-ages .treatment-process--tab');

  treatmentSeeMoreBtn.addEventListener('click', (e)=>{
    e.preventDefault();

    tabButtons.forEach(button=>{
      button.classList.toggle('active')
      resizeSliderImages()
    })

    tabs.forEach(button=>{
      button.classList.toggle('open')
      resizeSliderImages()
    })


    jumpto('treatmentCases')
  })
}

function jumpto(anchor){
  window.location.href = "#"+anchor;
}

const revealSliders = document.querySelectorAll('.reveal-slider');
function resizeSliderImages(){
  let sliderWrapperWidth = 0;

  revealSliders.forEach(slide=>{
    // let slideWidth = slide.clientWidth;
    let positionInfo = slide.getBoundingClientRect();
    let width = positionInfo.width;

    if (width > 0) {
      sliderWrapperWidth = width
    }
  })

  revealSliders.forEach((slider)=>{

    const beforeImage = slider.querySelector('.reveal-slider--image-before');
    const afterImage = slider.querySelector('.reveal-slider--image-after');

    beforeImage.style.width = sliderWrapperWidth + 'px';
    afterImage.style.width = sliderWrapperWidth + 'px';
  })
}
