window.addEventListener('DOMContentLoaded', ()=>{

    const countriesFilterWrapper = document.querySelector('.countries-filter-wrapper');
    const representativesListWrappers = document.querySelectorAll('.representatives-list-wrapper');

    if (!countriesFilterWrapper){
        return;
    }

    const filterInputs = countriesFilterWrapper.querySelectorAll('.filter-input');

    const allCountriesFilter = document.getElementById('allCountries');
    const activeFilters = ['allCountries'];

    filterInputs.forEach(input=>{
        input.addEventListener('change', (event)=>{
            const filterID = input.id;

            if (filterID === 'allCountries') {
                activeFilters.length = 0;
                activeFilters.push('allCountries');
                allCountriesFilter.checked = true;

                filterInputs.forEach(i => {
                    if (i.id !== 'allCountries') {
                        i.checked = false;
                    }
                });
            } else if (activeFilters.includes(filterID)) {
                activeFilters.splice(activeFilters.indexOf(filterID), 1);

                if (activeFilters.length === 0) {
                    activeFilters.push('allCountries');
                    allCountriesFilter.checked = true;
                }
            } else {
                activeFilters.push(filterID);

                if (activeFilters.includes('allCountries') && activeFilters.length > 1){
                    activeFilters.splice(activeFilters.indexOf('allCountries'), 1);
                    allCountriesFilter.checked = false;
                }
            }
            console.log(activeFilters)
            showRepresentativesBlocks();
        });
    });

    function showRepresentativesBlocks(){
        representativesListWrappers.forEach(wrapper => {
            const country = wrapper.getAttribute('data-country');
            if (!activeFilters.includes(country) && !activeFilters.includes('allCountries')) {
                wrapper.classList.add('hidden');
            } else {
                wrapper.classList.remove('hidden');
            }
        });
    }
})