const navigationMain = document.getElementById('navigation');
const hamburgerButton = document.getElementById('nav-toggle');
const html = document.documentElement;
const body = document.body


hamburgerButton.onclick = function () {
  if (hamburgerButton.classList.contains('active')) {


    // navigationMain.classList.remove('overlay', 'open');
    navigationMain.classList.remove('open');

    body.classList.remove('overlay');


    setTimeout(function () {
      // navigationMain.classList.remove('overlay-active')
      hamburgerButton.classList.remove('active')

      body.classList.remove('overlay-active');
    }, 300)
    html.classList.remove('no-scroll')
  } else {
    // navigationMain.classList.add('overlay', 'overlay-active', 'open')
    navigationMain.classList.add('open' )
    body.classList.add('overlay', 'overlay-active')



    hamburgerButton.classList.add('active')
    html.classList.add('no-scroll')
  }
}
