import gsap, { Power2 } from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);
const backTop = document.getElementById('back-top');

if (!backTop) {
  return
}

backTop.addEventListener('click', () => {
  gsap.to(window, {
    scrollTo: 0,
    duration: 2,
    ease: Power2.easeOut
  });
});

