// import Hls from 'hls.js'

//
//
// // MOBILE VIDEOS
//
// const rotationMainVideoMobile = document.getElementById('rotationMainVideoMobile');
//
//
// const videosPlayButton = document.getElementById('rotation-start-button');
//
// const options = {
//   controls: false,
//   title: false,
//   byline: false,
//   responsive: true,
//   pip: true,
//   autoplay: false,
//   muted: true,
//   transparent: false
// }
//
//
// videosPlayButton.addEventListener('click', ()=>{
//   const width = window.innerWidth;
//   let player;
//   if (width > 768){
//
//   }else {
//     player = new Vimeo.Player(rotationMainVideoMobile, options)
//     player.play()
//   }
// })
//
//
//


window.addEventListener("DOMContentLoaded", () => {
const frontPage = document.querySelector('.front-page');
if (frontPage) {
  let introVideo = '';
  let mainVideo = '';
  let introSrc = '';
  let mainSrc = '';

  let afterIntro = false;

  const timeStops = [0, 10, 20, 30, 40];
  const videoSteps = document.querySelectorAll('.video-steps .step');

  const videosStartButton = document.getElementById('rotation-start-button');
  const videosStartButtonSecond = document.getElementById('rotation-button');
  const rotationProgressBar = document.getElementById('rotationProgressBar');

  const videoPlayButton = document.getElementById('play-rotation-button')
  const videoPauseButton = document.getElementById('stop-rotation-button')

//content
  const rotationContent = document.querySelector('.rotation__initial');
  const rotationContentProgress = document.querySelector('.rotation__content')

  videosStartButton.addEventListener('click', () => {
    videosStartButton.style.display = 'none';
    videosStartButtonSecond.style.display = 'none';

    startRotationVideos()
  })

  videosStartButtonSecond.addEventListener('click', () => {
    videosStartButtonSecond.style.display = 'none';
    videosStartButton.style.display = 'none';

    startRotationVideos()
  })

  function startRotationVideos(){
    const screenWidth = window.innerWidth;

    if (screenWidth > 768) {
      rotationContent.style.display = 'none';
      introVideo = document.querySelector('#rotation-video .video1 video')
      mainVideo = document.querySelector('#rotation-video .video2 video')
    }
    else {
      introVideo = document.querySelector(
        '#rotation-video .video1-mobile video')
      mainVideo = document.querySelector('#rotation-video .video2-mobile video')
    }

    introSrc = introVideo.dataset.src;
    mainSrc = mainVideo.dataset.src;

    autoFocus()

    if (!afterIntro) {
      // introVideo.play();
      playVideo(introSrc, introVideo)
      introVideoEnd()
    }
    else {
      videoPlayButton.style.display = 'none';
      videoPauseButton.style.display = 'block';
      mainVideo.currentTime = 0;
      mainVideoTimeUpdate()
    }
  }


  function introVideoEnd () {
    introVideo.addEventListener('ended', (event) => {
      rotationContentProgress.classList.remove('u-hidden')
      rotationContentProgress.style.opacity = 1;
      videoPlayButton.style.display = 'none';
      videoPauseButton.style.display = 'block';

      mainVideoTimeUpdate()
    });
  }

  let progressDot = document.querySelector('.__progress .dot')
  let progressLine = document.querySelector('.__progress .line')
  progressLine.style.transform = 'scaleX(1)';

  function mainVideoTimeUpdate () {
    mainVideo.currentTime = 0;
    // mainVideo.play()
    playVideo(mainSrc, mainVideo)

    let hiddenIntro = false;
    mainVideo.addEventListener('timeupdate', () => {

      if (mainVideo.currentTime >= .1 && hiddenIntro === false) {
        introVideo.style.opacity = 0;
        introVideo.style.display = 'none';
        hiddenIntro = true;
      }

      rotationProgressBar.value = mainVideo.currentTime;
      const x = rotationProgressBar.value * 100 / rotationProgressBar.max
      progressDot.style.left = x + '%'
      progressLine.style.width = x + '%'
      updateSteps(mainVideo.currentTime)
    })

    mainVideo.addEventListener('ended', () => {
      videosStartButton.style.display = 'block';
      videosStartButtonSecond.style.display = 'block';
      videoPauseButton.style.display = 'none';
      videoPlayButton.style.display = 'block';

      afterIntro = true;
    })
  }

  rotationProgressBar.addEventListener('input', () => {
    const x = rotationProgressBar.value * 100 / rotationProgressBar.max
    progressDot.style.left = x + '%'
    mainVideo.currentTime = rotationProgressBar.value
  })

  videoPauseButton.addEventListener('click', () => {
    videoPlayButton.style.display = 'block';
    videoPauseButton.style.display = 'none';
    mainVideo.pause()
  })

  videoPlayButton.addEventListener('click', () => {
    videoPlayButton.style.display = 'none';
    videoPauseButton.style.display = 'block';
    videosStartButton.style.display = 'none';
    videosStartButtonSecond.style.display = 'none';
    mainVideo.play()
  })

  videoSteps.forEach((step, index) => {
    step.addEventListener('click', () => {
      mainVideo.currentTime = timeStops[step.dataset.video]
    })
  })

  function updateSteps (time) {

    if (time >= timeStops[timeStops.length - 1]) {
      videoSteps[videoSteps.length - 1].classList.add('active')
      videoSteps[videoSteps.length - 2].classList.remove('active')
    }
    else {
      timeStops.forEach((stop, index) => {
        if (time >= stop && time < timeStops[index + 1]) {
          videoSteps[index].classList.add('active');
        }
        else {
          videoSteps[index].classList.remove('active')
        }
      })
    }
  }




  function playVideo(videoSrc, videoBlock){
    var error = document.getElementById("error");






    // if(Hls.isSupported()) {
    //   hls = new Hls();
    //   console.log('if Hls is supported')
    //
    //   // hls.loadSource('https://player.vimeo.com/external/670166997.m3u8?s=0e222e6024b5988f5043a02e33e6c675aac65da6');
    //   hls.loadSource(videoSrc);
    //   hls.attachMedia(videoBlock);
    //   // hls.on(Hls.Events.ERROR, function (event, data) {
    //   //   if (data.fatal) {
    //   //     console.log('if data fatal')
    //   //     switch (data.type) {
    //   //       case HLS.ErrorTypes.NETWORK_ERROR:
    //   //         // try to recover network error
    //   //         hls.startLoad();
    //   //         break;
    //   //       case HLS.ErrorTypes.MEDIA_ERROR:
    //   //         hls.recoverMediaError();
    //   //         break;
    //   //       default:
    //   //         // cannot recover
    //   //         hls.destroy();
    //   //         break;
    //   //     }
    //   //   }else if (data.details === 'internalException' && data.type === 'otherError' && isMobile()) {
    //   //     console.log('else if fatal')
    //   //     const level = last(this.getLevels());
    //   //     hls.removeLevel(level?.level, level?.urlId);
    //   //     hls.startLoad();
    //   //   }
    //   // });
    //
    //   // hls.on(Hls.Events.MANIFEST_PARSED,function() {
    //   //   videoBlock.play()
    //   // });
    // }
    // else if (videoBlock.canPlayType('application/vnd.apple.mpegurl')) {
    //   console.log('else if ')
    //   // video.src = 'https://player.vimeo.com/external/670166997.m3u8?s=0e222e6024b5988f5043a02e33e6c675aac65da6';
    //   videoBlock.src = videoSrc;
    //   videoBlock.addEventListener('canplay',function() {
    //     console.log('CANPLAY!')
    //     // mainVideo.play();
    //     // fadeIn(mainVideo)
    //     // setTimeout(function () {
    //     setTimeout(function () {
    //       videoBlock.play()
    //     }, 900)
    //     // }, 1000)
    //   });
    // }
    // else {
    //   console.log('else should load MP4 video....')
    //   // if (w > 1024) {
    //   //   videoSrc = videoBlock.dataset.mp4src;
    //   videoBlock.src =  videoBlock.dataset.mp4src
    //   // }
    //   // fadeIn(mainVideo)
    //   // setTimeout(function () {
    //   videoBlock.play()
    //   // }, 1000)
    // }



    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(videoBlock);

      error.innerText = "Hls.isSupported()";
      videoBlock.play()
    } else if (videoBlock.canPlayType("application/vnd.apple.mpegurl")) {
      videoBlock.src = videoSrc;
      error.innerText = "vnd.apple.mpegurl";
      //
      // If no native HLS support, check if HLS.js is supported
      //
      videoBlock.play()
    } else {
      videoSrc = videoBlock.dataset.mp4src;
      error.innerText = "Should play mp4?????";
      videoBlock.play()
    }


  }








}


function autoFocus() {

  const videosSection = document.getElementById('rotation-video');
  let top = videosSection.getBoundingClientRect().top;

  if (top > 0) {
    window.scrollBy(0,2);
  }else if (top < 0) {
    window.scrollBy(0,-2);
  }

  var scrolldelay = setTimeout(()=>{

    if (top > 3) {
      autoFocus()
    } else if (top < -3) {
      autoFocus()
    }else{
      clearTimeout(scrolldelay)
    }

  },0.001);
}

});


























