// const frontPage = document.querySelector('.front-page');
// if (frontPage) {
//
//   let pageParams = {
//     windowWidth: null,
//     device: '',
//     cookie: false,
//     videoPlaying: false
//   }
//   const options = {
//     controls: false,
//     title: false,
//     byline: false,
//     responsive: true,
//     pip: true,
//     autoplay: true,
//     muted: true,
//   };
//
//   let videoPlayer;
//   let video;
//
//   const homepageDesktopVideo = document.getElementById('homepageDesktopVideo')
//   const homepageMobileVideo = document.getElementById('homepageMobileVideo')
// // DESKTOP IMAGE
//   const mainHeroImageDesktop = document.getElementById('mainHeroImageDesktop');
// // MOBILE IMAGE AND FACE
//   const landingMobileBg = document.getElementById('landingMobileBg')
//   const landingMobileFace = document.getElementById('landingMobileFace');
//
//   const mainVideoContent = document.getElementById('heroSectionContent');
//
//   const replayVideoBtn = document.getElementById('replay_video');
//
//   window.addEventListener('DOMContentLoaded', () => {
//     let screenWidth = window.innerWidth;
//     const videoCookie = getCookie('videoCookie');
//
//     if (screenWidth > 768) {
//       pageParams.device = 'desktop'
//     }
//     else {
//       pageParams.device = 'mobile'
//     }
//     pageParams.windowWidth = screenWidth;
//
//     if (videoCookie === '') {
//       pageParams.videoPlaying = true
//       hideAllImages()
//       showVideo()
//       setCookie('videoCookie', true, 30);
//     }else {
//       showAllImages()
//     }
//   })
//
//   function hideAllImages () {
//     mainHeroImageDesktop.style.opacity = 0;
//     landingMobileBg.style.opacity = 0;
//     landingMobileFace.style.opacity = 0;
//     mainVideoContent.style.opacity = 0;
//   }
//   function showAllImages () {
//     mainHeroImageDesktop.style.opacity =1;
//     landingMobileBg.style.opacity = 1;
//     landingMobileFace.style.opacity = 1;
//     mainVideoContent.style.opacity = 1;
//   }
//
//   function showVideo () {
//     if (pageParams.device === 'desktop') {
//       video = document.getElementById('homepageDesktopVideo');
//       videoPlayer = new Vimeo.Player(video, options)
//       videoPlayer.play();
//       videoPlayer.on('timeupdate', function (data) {
//         let end = data.duration - 0.5
//         if (data.seconds >= end) {
//           fadeOutItem(video);
//           setTimeout(function () {
//             fadeInItem(mainHeroImageDesktop);
//             fadeInItem(mainVideoContent)
//           }, 500)
//         }
//       });
//     }
//     else {
//       video = document.getElementById('homepageMobileVideo');
//       videoPlayer = new Vimeo.Player(video, options)
//       videoPlayer.play();
//       videoPlayer.on('timeupdate', function (data) {
//         let end = data.duration - 0.5
//         if (data.seconds >= end) {
//           fadeOutItem(video);
//           setTimeout(function () {
//             fadeInItem(mainVideoContent);
//             fadeInItem(landingMobileBg)
//             fadeInItem(landingMobileFace)
//           }, 500)
//         }
//       });
//     }
//
//     videoPlayer.on('ended', function (data) {
//       videoPlayer.destroy()
//       pageParams.videoPlaying = false
//     });
//   }
//
//   function fadeOutItem (item) {
//     item.style.opacity = 0;
//   }
//
//   function fadeInItem (item) {
//     item.style.opacity = 1;
//   }
//
//   replayVideoBtn.addEventListener('click', () => {
//     hideAllImages();
//     if (pageParams.device === 'desktop') {
//       fadeInItem(homepageDesktopVideo)
//     }
//     else {
//       fadeInItem(homepageMobileVideo)
//     }
//     showVideo();
//   })
//
//   window.addEventListener('resize', function () {
//
//     let screenWidth = window.innerWidth;
//
//     if (screenWidth > 768) {
//       pageParams.device = 'desktop'
//     }
//     else {
//       pageParams.device = 'mobile'
//     }
//     pageParams.windowWidth = screenWidth;
//
//     if (pageParams.videoPlaying !== true) {
//       if (screenWidth > 768) {
//         fadeInItem(mainHeroImageDesktop)
//         fadeOutItem(landingMobileBg)
//         fadeOutItem(landingMobileFace)
//       }
//       else {
//         fadeOutItem(mainHeroImageDesktop)
//         fadeInItem(landingMobileBg)
//         fadeInItem(landingMobileFace)
//       }
//     }
//
//   })
//
// }

var hls;
var windowWidth = window.innerWidth;
// HOMEPAGE VIDEO
const frontPage = document.querySelector('.front-page');
if (frontPage) {
// ELEMENTS
  var mainVideo = document.getElementById('mainVideo');
  var mainVideoContent = document.getElementById('heroSectionContent');

// DESKTOP IMAGE
  var mainHeroImageDesktop = document.getElementById('mainHeroImageDesktop');

// MOBILE IMAGE AND FACE
  var landingMobileBg = document.getElementById('landingMobileBg')
  var landingMobileFace = document.getElementById('landingMobileFace')

  var w = window.innerWidth;
  var replayVideoBtn = document.getElementById('replay_video');

  window.addEventListener('DOMContentLoaded', function () {
    var videoCookie = getCookie('videoCookie');

    if (videoCookie === '') {
      showVideo('gtag');

      setCookie('videoCookie', true, 30);
    } else {
      mainVideoContent.style.opacity = '1'
      mainHeroImageDesktop.style.opacity = '1';
      landingMobileBg.style.opacity = '1'
      landingMobileFace.style.opacity = '1';
    }
  })

  function showVideo(func) {
    var heroSectionContent = document.getElementById('heroSectionContent');
    fadeOut(heroSectionContent);

    // if (func == 'gtag'){
    //   if (typeof gtag == 'function') {
    //     console.log('video playing')
    //     gtag('event', 'Video play', {
    //       'event_category' : 'Top home page.',
    //     });
    //   }

    // }

    let source;

    if (w > 1024) {
      source = mainVideo.dataset.src;
      mainVideoContent.style.opacity = '0'
      mainHeroImageDesktop.style.opacity = '0';

    } else {
      source = mainVideo.dataset.mobilesrc;
      landingMobileBg.style.opacity = '0'
      landingMobileFace.style.opacity = '0';
      console.log('Here: screen mobile')

    }

    // var video = document.getElementById('mainVideo');
    if(Hls.isSupported()) {
      hls = new Hls();
      console.log('Here: HLS works')
      // hls.loadSource('https://player.vimeo.com/external/670166997.m3u8?s=0e222e6024b5988f5043a02e33e6c675aac65da6');
      hls.loadSource(source);
      hls.attachMedia(mainVideo);
      hls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case HLS.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              console.log('Here: HLS startLoad')
              hls.startLoad();
              break;
            case HLS.ErrorTypes.MEDIA_ERROR:
              console.log('Here: HLS recoverMediaError')
              hls.recoverMediaError();
              break;
            default:
              // cannot recover
              console.log('Here: HLS destroy')
              hls.destroy();
              break;
          }
        }else if (data.details === 'internalException' && data.type === 'otherError' && isMobile()) {
          const level = last(this.getLevels());

          hls.removeLevel(level?.level, level?.urlId);

          hls.startLoad();
        }
      });


      hls.on(Hls.Events.MANIFEST_PARSED,function() {
        // mainVideo.play();
        fadeIn(mainVideo)
        setTimeout(function () {
          console.log('setTimeout 1')
          mainVideo.play()
        }, 900)
      });
    }
    else if (mainVideo.canPlayType('application/vnd.apple.mpegurl')) {
      console.log('Here: else if check if HLS works on iphone')

      // video.src = 'https://player.vimeo.com/external/670166997.m3u8?s=0e222e6024b5988f5043a02e33e6c675aac65da6';
      mainVideo.src = source;
      console.log('Are we here')

      mainVideo.addEventListener('loadedmetadata',function() {
        console.log('Does this work?????')
          mainVideo.play()
      });

      mainVideo.addEventListener('canplay',function() {
        console.log('Are we here? CANPLAY')
        // mainVideo.play();
        testfunction()
        fadeIn(mainVideo)
        setTimeout(function () {
          console.log('setTimeout 2')
          mainVideo.play()
        }, 900)
      });
    } else {
      console.log('Here: else play just MP4')

      if (w > 1024) {
        source = mainVideo.dataset.mp4src;
      }
      else {
        source = mainVideo.dataset.mp4srcmobile;
      }
      fadeIn(mainVideo)
      setTimeout(function () {
        console.log('setTimeout 3')
        mainVideo.play()
      }, 900)
    }



    // mainVideo.play()

    // mainVideo.oncanplay = (event) => {
    //   console.log('Video can start, but not sure it will play through.');
      // fadeIn(mainVideo)
      // setTimeout(function () {
      //   mainVideo.play()
      // }, 1000)
    // };

    // mainVideo.addEventListener('loadeddata', (e) => {
    //   console.log('video loaded load data')
      //Video should now be loaded but we can add a second check
      // fadeIn(mainVideo)
      // setTimeout(function () {
      //   mainVideo.play()
      // }, 500)
    // });
  }


  function  testfunction(){
    console.log('test function')
  }

  replayVideoBtn.addEventListener('click', function () {
    showVideo()
  });

  mainVideo.ontimeupdate = function () {
    if (mainVideo.currentTime > 8.5) {
      videoEnd();
    }
  };

  function videoEnd() {
    fadeOut(mainVideo);
    var w = window.innerWidth;
    if (w > 1024) {
      setTimeout(function () {
        fadeIn(mainHeroImageDesktop)
      }, 500)
    } else {
      setTimeout(function () {
        fadeIn(landingMobileBg)
        fadeIn(landingMobileFace)
      }, 500)
    }

    var heroSectionContent = document.getElementById('heroSectionContent');
    fadeIn(heroSectionContent)
  }

  window.addEventListener('resize', function () {
    console.log('windowWidth ', windowWidth)
    console.log('resize event!!');
    var w = window.innerWidth;
    console.log('w ', w)
    if (windowWidth !== w){
      if (w > 768) {
        mainVideo.src = mainVideo.dataset.src;
        fadeIn(mainHeroImageDesktop)
        fadeOut(landingMobileBg)
        fadeOut(landingMobileFace)
      } else {
        mainVideo.src = mainVideo.dataset.mobilesrc;
        fadeOut(mainHeroImageDesktop)
        fadeIn(landingMobileBg)
        fadeIn(landingMobileFace)
      }
    }

  })

  function fadeIn(item) {
    if (!item.style.opacity) {
      item.style.opacity = '0';
    }
    item.style.opacity = '1';
  }

  function fadeOut(item) {
    if (!item.style.opacity) {
      item.style.opacity = '1';
    }
    item.style.opacity = '0';
  }
}

