import { gsap, ScrollTrigger} from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Draggable)

window.addEventListener('DOMContentLoaded', ()=>{


  const patientPage = document.getElementById('patientPage');

  if (patientPage) {
    const desktopImages = document.querySelectorAll('.reasons-section-bg-wrapper img')
    const sections = document.querySelectorAll('.reasons-section-content');
    const videos = document.querySelectorAll('.swiper-slide .case-video video')

    ScrollTrigger.create({
      trigger: '#cases_slider',
      scrub: false,
      markers: false,
      onEnter: () => {
        videos[0].play()
      },
      start: '40% center',
    });


    sections.forEach((section) => {
      ScrollTrigger.matchMedia({
        // desktop
        "(min-width: 768px)": function () {

          ScrollTrigger.create({
            trigger: section,
            start: "59% bottom",
            end: "bottom center",
            scrub: true,
            markers: false,
            onEnter: () => {
              myEnterFunc(section, self)
            },
            onEnterBack: () => {
              myEnterFunc(section, self)
            },
          });

        },

      });
    })


    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function () {

        ScrollTrigger.create({
          trigger: '.reasons-section-bg-wrapper',
          scrub: true,
          anticipatePin: 1,
          pin: true,
          pinSpacing: false,
          markers: false,
          onEnter: () => {
            showFirstParagraph()
          },
          start: 'top top',
          endTrigger: sections[sections.length - 1],
          end: "bottom bottom"
        });

      },
    });
    // })

    // tl.progress(0)
    function showFirstParagraph () {
      const p = document.querySelector('.reasons-content.first-content' +
        ' .reasons-text p');

      if (!p.classList.contains('show-it')) {
        p.classList.add('show-it')
      }

    }



    function myEnterFunc (el, self) {
      let imgID = el.dataset.img;

      desktopImages.forEach(img=>{
        if (img.dataset.img == imgID){
          img.classList.add('active')
        }else{
          img.classList.remove('active')
        }
      })
    }

    const targets = document.querySelectorAll('.reasons-text p');

    const showUp = target => {

      let options = {
        root: null,
        rootMargin: '-140px',
        threshold: 0.5
      }

      const io = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            let elem = entry.target;
            elem.classList.add('show-p')
          }
        });
      }, options);

      io.observe(target)
    };

    targets.forEach(showUp);

    // window.addEventListener('DOMContentLoaded', () => {
    const videoBlocks = document.querySelectorAll('.aligners-case-video');
    videoBlocks.forEach(video=>{
      video.pause()
    })


    const caseTestBlocks = document.querySelectorAll('.aligners-case')

    function videoActions (caseBlock, index) {
      const video = caseBlock.querySelector('.aligners-case-video');
      const timeline = caseBlock.querySelector('.timeline');
      const timelineProgress = caseBlock.querySelector('.timeline__progress')
      const drag = caseBlock.querySelector('.timeline__drag');
      // const progressBar = caseBlock.querySelector('.case-video' +
      //   ' .video-case-progress-bar') //irgi nereikia


      video.onplay = function() {
        gsap.ticker.add(vidUpdate);
      };
      video.onpause = function() {
        gsap.ticker.remove(vidUpdate);
      };
      video.onended = function() {
        gsap.ticker.remove(vidUpdate);
      };

      function vidUpdate() {
        gsap.set(timelineProgress, {
          scaleX: (video.currentTime / video.duration).toFixed(5)
        });
        gsap.set(drag, {
          x: (video.currentTime / video.duration * timeline.offsetWidth).toFixed(4)
        });
      }


      Draggable.create(drag, {
        type: 'x',
        trigger: timeline,
        bounds: timeline,
        onPress: function(e) {
          video.currentTime = (this.x / this.maxX * video.duration).toFixed(1);
          gsap.set(this.target, {
            x: this.pointerX - timeline.getBoundingClientRect().left
          });
          video.pause()
          this.update();
          var progress = this.x / timeline.offsetWidth;
          gsap.set(timelineProgress, {
            scaleX: progress
          });
        },
        onDrag: function() {
          let time = (this.x / this.maxX * video.duration).toFixed(1);
          videoUpdate(video, time)
          var progress = this.x / timeline.offsetWidth;
          gsap.set(timelineProgress, {
            scaleX: progress
          });
        },
        onRelease: function(e) {
          e.preventDefault();
        }
      });









      //NEREIKIA
      // videoBlock.addEventListener('loadeddata', function () {
      //   progressBar.max = videoBlock.duration;
      // });

      //NEREIKIA
      // const dot = caseTest.querySelector('.cases-video-controls .dot');
      // let dotWidth = dot.offsetWidth;

      //NEREIKIA
      // window.addEventListener('resize', ()=>{
      //   dotWidth = dot.offsetWidth;
      // })

      //NEREIKIA
      // const progress = caseTest.querySelector('.cases-video-controls' +
      //   ' .activeProgress');
      // progressBar.addEventListener('input', () => {
      //   videoBlock.pause()
      //   changeProgressBar()
      // })

      //NEREIKIA
      // progressBar.addEventListener('change', () => {
      //   videoBlock.pause()
      //   changeProgressBar()
      // })

      //NEREIKIA
      // videoBlock.addEventListener("timeupdate", function (ev) {
      //   const x = ev.target.currentTime * 100 / progressBar.max;
      //   // progress.style.width = x + '%';
      //   dot.style.left = `calc(${x}% - ${x * dotWidth / 100}px)`;
      //
      // });

      //NEREIKIA
      // function changeProgressBar () {
      //   videoBlock.currentTime = progressBar.value;
      //   const x = progressBar.value * 100 / progressBar.max;
      //   progress.style.width = x + '%';
      //   dot.style.left = `calc(${x}% - ${x * dotWidth / 100}px)`;
      // }
    }

    let currentTime = 0;
    function videoUpdate(video, time){
      if (currentTime != time){
        video.currentTime = time;
        currentTime = time;
        // console.log('time updated ')
        // timeStats.innerText = time;
      }
    }

    caseTestBlocks.forEach((block, index) => {
      videoActions(block, index)
    })
    // })

    const swiper = new Swiper('#cases_slider', {
      effect: 'fade',
      speed: 500,
      fadeEffect: {
        crossFade: true
      },
      // direction: 'horizontal',
      loop: false,
      slidesPerView: 1,
      allowTouchMove: false,
      disableOnInteraction: false,
      noSwiping: false,
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    swiper.on('slideChange', function () {
      myCallbackfunction(this);
    });

    function myCallbackfunction(data){

      videos.forEach(video=>{
        video.currentTime = 0;
        video.pause()
      })

      videos[data.realIndex].play()
    }

  }

})

function appHeight() {
  document.documentElement.style.setProperty('--vh', window.innerHeight/100 + 'px');
  document.documentElement.style.setProperty('--vw', window.innerWidth/100 + 'px');
}
setTimeout(()=> {
  appHeight();
}, 500)

window.addEventListener( 'orientationchange', function() {
  appHeight();
});

window.addEventListener( 'resize', function() {
  console.log('resize')
  appHeight();
});

