import { gsap, ScrollTrigger} from "gsap/all";
// don't forget to register plugins
gsap.registerPlugin(ScrollTrigger);
const animatedLines = document.querySelectorAll('.line-test');

animatedLines.forEach((line)=>{
  gsap.to(line, {
    scrollTrigger: {
      trigger: line,
      start: "top 85%",
      end: "bottom top",
      // toggleActions: "restart none none none",
      scrub: true,
      markers: false,
    },
    width: "100%",
    transformOrigin: "left center",
    ease: "ease-in-out",
  });
})

