window.addEventListener('DOMContentLoaded', ()=>{
    const stickyButtonCookie = getCookie('sticky-button');
    let firsTimeCookie = sessionStorage.getItem("first-time-visit");
  
    if (stickyButtonCookie === '') {
      sessionStorage.setItem("first-time-visit", 'true');
      setCookie('sticky-button', 'true', 365);
    }
  
    if (stickyButtonCookie !== '' && firsTimeCookie === null){
      showStickyButton()
    }
  })
  
  function showStickyButton() {
    const stickyButton = document.getElementById('sticky-button');
    stickyButton && stickyButton.classList.add('show');
  }
  
