const sectionContentSlider = document.getElementById('section-content-slider');

if (sectionContentSlider) {

  const swiper = new Swiper('#section-content-slider', {
    direction: 'horizontal',
    slidesPerView: 1.01,
    spaceBetween: 52,
    freeMode: {
      enabled: true,
      sticky: true,
    },
    breakpoints: {

      640: {
        slidesPerView: 2.4,
        spaceBetween: 95,
      }
    }
  });
}
