// HERE GOES HEADER ANIMATION ON SCROLL
import gsap, { Power2 } from 'gsap'

const header = document.getElementById('header');

gsap.set(header, { y: '-100%', opacity: 0 });
gsap.set('#main', { opacity: 0 });
gsap.to('#main', {
  opacity: 1,
  duration: 0.5,
  ease: Power2.easeOut,
  onComplete: () => {
    gsap.to(header, {
      y: '0',
      opacity: 1,
      duration: 0.5,
      ease: Power2.easeOut,
      onComplete: () => {
        header.style = '';
      }
    });
  }
});

