const resutlsToggle = document.querySelectorAll('.results-toggle');

if (resutlsToggle.length > 0) {

  resutlsToggle.forEach((result)=>{
    let toggleButton = result.querySelector('.resultsButton');
    let toggleButtonText = result.querySelector('.resultsButton span');
    let resultLabel = result.querySelector('.resultLabel');

    let beforeImg = result.querySelector('.results-toggle--image-before')
    let afterImg = result.querySelector('.results-toggle--image-after')


    toggleButton. addEventListener('click', ()=>{
      result.classList.toggle('show-before')
      toggleItems(result, resultLabel, toggleButtonText, beforeImg , afterImg)

    })

  })

}


function toggleItems(result, resultLabel, toggleButtonText,beforeImg , afterImg ){
  if (result.classList.contains('show-before')) {
    resultLabel.innerText = resultLabel.dataset.beforetext;
    toggleButtonText.innerText = toggleButtonText.dataset.beforetextbutton;
    beforeImg.classList.toggle('show-image' );
    afterImg.classList.toggle('show-image' )
  }else{
    resultLabel.innerText = resultLabel.dataset.aftertext;
    toggleButtonText.innerText = toggleButtonText.dataset.aftertextbutton;
    beforeImg.classList.toggle('show-image' );
    afterImg.classList.toggle('show-image' )
  }

}


