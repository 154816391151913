const revealSliders = document.querySelectorAll('.reveal-slider');

const revealSliderTab = document.getElementById('revealSliderTab');

if (revealSliderTab) {
  revealSliderTab.addEventListener('click', ()=>{
    setTimeout(()=>{
      resizeSliderImages()
    },0)
  })
}


if (revealSliders.length > 0) {
  const sliders = document.querySelectorAll('._js-reveal-slider');
  sliders.forEach((rangeSlider, index)=>{
    rangeSlider.addEventListener('input', ()=>{
      revealImage(index,rangeSlider.value )
    })
  })

}

function revealImage(index, value){
  const revealImageWrapper = revealSliders[index].querySelector('.reveal-slider--image-after-wrapper');
  const revealImageHandle = revealSliders[index].querySelector('.reveal-slider--handle');
  revealImageWrapper.style.width = value + '%';
  revealImageHandle.style.left = value + '%';
}

function resizeSliderImages(){
  let sliderWrapperWidth = 0;

  revealSliders.forEach(slide=>{
    // let slideWidth = slide.clientWidth;
    let positionInfo = slide.getBoundingClientRect();
    let width = positionInfo.width;

    if (width > 0) {
      sliderWrapperWidth = width
    }
  })

  revealSliders.forEach((slider)=>{

    const beforeImage = slider.querySelector('.reveal-slider--image-before');
    const afterImage = slider.querySelector('.reveal-slider--image-after');

    beforeImage.style.width = sliderWrapperWidth + 'px';
    afterImage.style.width = sliderWrapperWidth + 'px';
  })
}


window.addEventListener('resize', ()=>{
  resizeSliderImages()
})

window.addEventListener('DOMContentLoaded', ()=>{
  resizeSliderImages()
})
