
const swiper = new Swiper('.swiper.timeline-slider', {
  // Optional parameters
  direction: 'horizontal',
  slidesPerView: 1.2,
  freeMode: {
    enabled: true,
    sticky: true,
  },
  breakpoints: {

    640: {
      slidesPerView: 2.4,
    }
  }
});

const sliderContent = document.querySelectorAll('.swiper-slide .timeline-content p');
sliderContent.forEach(function (item) {
  let span = document.createElement('span');
  span.innerText = item.innerHTML.trim().split(' ').splice(0, 14).join(' ');
  let dots = document.createElement('span');
  dots.innerHTML = ' ...';
  dots.classList.add('dots');
  span.append(dots);
  item.innerHTML = item.innerHTML.trim().split(' ').slice(14, (item.innerHTML.trim().split('')).length).join(' ');
  let contentWrapper = item.closest('.timeline-content');
  contentWrapper.prepend(span);
  // console.log(item.innerHTML.split(' ').splice(0, 14))
});

// let excerption = sliderContent[0].splice(0, 14).split(-1)


const timelineContents = document.querySelectorAll('.timeline-content');
// const myAccrodionCustom = new HandyCollapse({
//   nameSpace: "hc", // Note: Be sure to set different names when creating multiple instances
//   activeClass: "is-active",
//   isAnimation: true,
//   closeOthers: true,
//   animationSpeed: 300,
//   cssEasing: "ease",
//   onSlideStart: (isOpen, contentID) => {
//     timelineContents.forEach(item=> item.classList.remove('content-show'))
//     console.log('onSlideStart ',isOpen);
//     const buttonEl = document.querySelectorAll(`[data-hc-control='${contentID}']`);
//     const contentEl = document.querySelector(`[data-hc-content='${contentID}']`);
//     console.log(buttonEl);
//     if (isOpen) {
//       const contentWrapper = contentEl.closest('.timeline-content');
//       contentWrapper.classList.add('content-show')
//     }
//   },
//   onSlideEnd: (isOpen, contentID) => {
//     console.log('onSlideEnd ', isOpen);
//     const contentEl = document.querySelector(`[data-hc-content='${contentID}']`);
//     if (!isOpen) {
//       const contentWrapper = contentEl.closest('.timeline-content');
//       contentWrapper.classList.remove('content-show')
//     }
//   }
// });


const timelineShowMoreButtons = document.querySelectorAll('.timeline-content--show-more');

timelineShowMoreButtons.forEach((button)=>{
  button.addEventListener('click', ()=>{

    let span = button.querySelector('span');
    const readMoreText = button.dataset.readmore;
    const readLessText = button.dataset.readless;
    timelineShowMoreButtons.forEach((btn)=>{
      const swiperSlide = btn.closest('.swiper-slide')
      swiperSlide.classList.remove('open')
      let span = btn.querySelector('span');
      span.innerText = readMoreText
    })

    const swiperSlide = button.closest('.swiper-slide')


    if (button.classList.contains('is-active')){
      span.innerText = readMoreText;
      swiperSlide.classList.remove('open')
    }else{
      span.innerText = readLessText;
      swiperSlide.classList.add('open')
    }

  })


})
