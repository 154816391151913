const contactForm = document.getElementById('contactForm');

if (contactForm) {
  const inputs = document.querySelectorAll('#contactForm .input');

  inputs.forEach((input)=>{
    const inputField = input.querySelector('input');
    const label = input.querySelector('label')
    if ( inputField ) {
      inputField.addEventListener('change', ()=>{
        if (inputField.value !== '') {
          label.classList.add('active-label')
        }else{
          label.classList.remove('active-label')
        }
      })
    }
  })
}



const dateInputs = document.querySelectorAll('.date-input');

if (dateInputs.length > 0) {
  dateInputs.forEach(input=>{
    const inputWrapper = input.closest('.input');
    const label = inputWrapper.querySelector('label');

    input.addEventListener("focus", ()=>{
      //console.log(label)
      //console.log('date input selected')
      //input.type = 'date';
      label.classList.add('active-label')
    })

    input.addEventListener('blur', ()=>{
      if (input.value === ''){
        //input.type = 'text';
        label.classList.remove('active-label')
      }
    })
  })
}


const labels = document.querySelectorAll('.question-images' +
  ' .wpcf7-list-item-label');

if (labels.length > 0) {
  labels.forEach(label=>{
    label.innerText = '';
  })
}
