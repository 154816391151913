function appHeight() {
  document.documentElement.style.setProperty('--vh', window.innerHeight/100 + 'px');
  document.documentElement.style.setProperty('--vw', window.innerWidth/100 + 'px');
}
setTimeout(()=> {
  appHeight();
}, 500)

window.addEventListener( 'orientationchange', function() {
  appHeight();
});

// ANIMATED ITEMS
const targets = document.querySelectorAll('.show-up');

const showUp = target => {

  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  }


  const io = new IntersectionObserver((entries, observer) => {
    // console.log(entries)
    entries.forEach(entry => {

      if (entry.isIntersecting) {
        const item = entry.target;
        item.classList.add('animated')


        observer.disconnect();
      }
    });
  }, options);

  io.observe(target)
};

targets.forEach(showUp);


//RESULTS SECTION
import { gsap, ScrollTrigger} from "gsap/all";

window.addEventListener('DOMContentLoaded', ()=>{
// don't forget to register plugins
  gsap.registerPlugin(ScrollTrigger);
  const resultsBox = document.getElementById('resultsSlider');

// animatedLines.forEach((line)=>{
  gsap.to(resultsBox, {
    scrollTrigger: {
      trigger: '#results',
      start: "top center",
      end: "top top",
      // toggleActions: "restart none none none",
      scrub: true,
      markers: false,
    },
    x: "0%",
    transformOrigin: "left top",
    ease: "linear",
  });
// })


  window.addEventListener('videoLoaded', function (e) {
    ScrollTrigger.refresh(true)

  }, false);

  const swiper = new Swiper('.swiper-results-slider', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView:4.5,
    spaceBetween: 20,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.8,
      },
      400: {
        slidesPerView: 2.2,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2.2,
      },
      // when window width is >= 640px
      1024:{
        slidesPerView: 3.5,
      },
      1920: {
        slidesPerView: 4.5
      }
    }

  });
})




// window.addEventListener('DOMContentLoaded', ()=>{
//   const mobileMovingHandWrapper = document.getElementById('mobileMovingHandWrapper');
//
//   if (mobileMovingHandWrapper) {
//     const mobileMovingHandImg = document.querySelector('#mobileMovingHandWrapper' +
//       ' img');
//
//     let movingHandHeight = mobileMovingHandImg.clientHeight;
//
//     mobileMovingHandWrapper.style.height = `${movingHandHeight + 50}px`;

    const tl = gsap.timeline({
      scrollTrigger:{
        trigger: '.home-page--advantages--wrapper--mobile',
        scrub: true,
        pin: true,
        pinSpacing: false,
        markers: false,
        start: 'top top',
        end: "+=58%"
      }
    })

    tl.to(".moving-hand-wrapper img", {opacity: 0.3, rotation:15, ease: "none"})
//   }
// })
