import { __ } from '@wordpress/i18n';

if ( document.querySelector('.question-form') ) {
    const smileValidate = new window.JustValidate('.question-form', {
        errorFieldCssClass: 'error',
        errorLabelStyle: '',
        successFieldCssClass: 'valid',
        focusInvalidField: false,
    });
    smileValidate
        .addRequiredGroup(
            '.question-wrapper__interested-list',
            __('One or more fields have an error. Please check and try' +
              ' again.', 'ordoline')
        )
        .addRequiredGroup(
            '#question-images',
            __('One or more fields have an error. Please check and try' +
              ' again.', 'ordoline')
        )
        .addRequiredGroup(
            '.question-wrapper__where-list',
            __('One or more fields have an error. Please check and try' +
              ' again.', 'ordoline')
        )
        .addField('#name', [
            {
                rule: 'required'
            }
        ])
        // .addField('#date_of_birth', [
        //     {
        //         rule: 'required'
        //     }
        // ])
        .addField('#country', [
            {
                rule: 'required'
            }
        ])
        .addField('#city', [
            {
                rule: 'required'
            }
        ])
        .addField('#email', [
            {
                rule: 'required',
                errorMessage: __('Email is required', 'ordoline'),
            },
            {
                rule: 'email',
                errorMessage: __('Email is invalid', 'orodline'),
            }
        ])
        .addField('#tel', [
            {
                rule: 'required'
            },
            {
                rule: 'number'
            }
        ])
        .addField('#agree', [
            {
                rule: 'required'
            }
        ]);

    document.querySelectorAll('.input').forEach(function(field) {
        let fieldType = field.querySelector('input');
        setInterval(function() {
            if ( fieldType.classList.contains('error') ) {
                field.classList.add('error')
                field.classList.remove('valid')
            } else if ( fieldType.classList.contains('valid') ) {
                field.classList.add('valid')
                field.classList.remove('error')
            }
            //console.log(fieldType.value.length)
        }, 50)
        fieldType.addEventListener('focus', (event) => {
            field.classList.add('focus')
        });
        fieldType.addEventListener('blur', (event) => {
            field.classList.remove('focus')
        });
        fieldType.addEventListener('change', function(event) {
            if (fieldType.value !== '') {
                fieldType.classList.add('valid')
            }else{
                fieldType.classList.remove('valid')
            }
            // if ( fieldType.value == '' ) {
            //     field.classList.remove('valid')
            //     fieldType.classList.remove('valid')
            // }
        });
    });
}

